import state from './tradingState'
import actions from './tradingActions'
import mutations from './tradingMutations'
import getters from './tradingGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
