export const policiesStatus = [
	{value: 'in_progress' , name: 'En curs'},
	{value: 'sent_to_client', name: 'Enviat'},
	{value: 'accepted', name: 'Acceptat'},
	{value: 'anulled', name: 'Anul·lada'},
	{value: 'denied', name: 'Denegada'},
]
export const policiesPeriods = [
	{value: 'monthly' , name: 'Mensual'},
	{value: 'quarterly', name: 'Trimestral'},
	{value: 'biannual', name: 'Semestral'},
	{value: 'annual', name: 'Anual'},
	{value: 'unique', name: 'Únic'},
]
export const healthModalities = [
	{value: 'completed' , name: 'Complet'},
	{value: 'quarterly', name: 'Copagaments'},
	{value: 'biannual', name: 'Reemborsament'},
]
export const insurancesTypes = [
	{value: 'accidents_particular', name: 'Accidents individual'},
	{value: 'accidents_contract', name: 'Accidents segons conveni'},
	{value: 'adm_directives', name: 'Administradors i directiu'},
	{value: 'travel_assistence', name: 'Asistencia en viatge'},
	{value: 'business', name: 'Comerç'},
	{value: 'community', name: 'Comunitat'},
	{value: 'car_motocycle', name: 'Cotxe/Moto'},
	{value: 'decennial', name: 'Decenal'},
	{value: 'deceases', name: 'Decesos'},
	{value: 'estalvi', name: 'Estalvi'},
	{value: 'house' , name: 'Llar'},
	{value: 'rent', name: 'Lloguers'},
	{value: 'pymes', name: 'PYMES'},
	{value: 'multirisk', name: 'Multirriesgos'},
	{value: 'rc_professional', name: 'R.C. professional'},
	{value: 'rc_particular', name: 'R.C. particular'},
	{value: 'health', name: 'Salut'},
	{value: 'daily_absence', name: 'Seguro baixa diària'},
	{value: 'triplesport', name: 'Triplesport'},
	{value: 'life', name: 'Vida'},
]