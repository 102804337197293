import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./requirementState"
import helpers from "@/resources/helpers/helpers"

export default {
    updateField,
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
    SET_REQUIREMENTS(state, payload) {
        state.requirements = payload
    },
    SET_REQUIREMENT(state, payload) {

        Object.entries(payload).forEach(([key, value]) => {
            state.requirement[key] = value
        })

        let dates = ['date_received_client', 'date_expiration', 'date_expiration_added_days', 'date_finalization']

        dates.forEach(date => {
            state.requirement[date] = (payload[date]) ? helpers.formatDate(payload[date]) : null
        })
    },
    SET_REQUIREMENT_FROM_REFERENCE(state, payload) {

        let fields = ['added_days', 'end_year', 'person_in_charge', 'invoice', 'init_year', 'period', 'trimester', 'description']

        fields.forEach(key => {
            state.requirement[key] = payload[key]
        })

    },
    SET_REQUIREMENT_REFERENCE(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement.requirement_reference[key] = value
        })
    },
    SET_REQUIREMENT_TAXES_TYPES(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement.taxes_types[key] = value.id
        })
    },
    SET_REQUIREMENT_HISTORICAL(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement.requirement_historical[key] = value
        })
    },
    SET_REQUIREMENT_ALLEGATIONS(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement.allegations[key] = value
        })
        Object.keys(payload)
        .sort()
        .reverse()
        .forEach(function (value, i) {
            console.log(value,i)
            state.requirement.allegations[i].order = Number(value)
        })
    },
    SET_REQUIREMENT_DOCUMENTS(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement_documents[key] = value
        })
    },
    SET_REQUIREMENT_DOCUMENTS_IRPF(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement_documents_irpf[key] = value
        })
    },
    SET_REQUIREMENT_DOCUMENTS_REFERENCE_IRPF(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement.requirement_documents_reference_irpf[key] = value
        })
    },
    SET_REQUIREMENT_DOCUMENTS_IVA(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement_documents_iva[key] = value
        })
    },
    SET_REQUIREMENT_DOCUMENTS_REFERENCE_IVA(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement.requirement_documents_reference_iva[key] = value
        })
    },
    SET_REQUIREMENT_DOCUMENTS_IS(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement_documents_is[key] = value
        })
    },
    SET_REQUIREMENT_DOCUMENTS_REFERENCE_IS(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement.requirement_documents_reference_is[key] = value
        })
    },
    SET_REQUIREMENT_DOCUMENTS_CENS(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement_documents_cens[key] = value
        })
    },
    SET_REQUIREMENT_DOCUMENTS_REFERENCE_CENS(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement.requirement_documents_reference_cens[key] = value
        })
    },
    SET_REQUIREMENT_DOCUMENTS_MODEL_347(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement_documents_model_347[key] = value
        })
    },
    SET_REQUIREMENT_DOCUMENTS_REFERENCE_MODEL_347(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement.requirement_documents_reference_model_347[key] = value
        })
    },
    SET_REQUIREMENT_DOCUMENTS_MODEL_180(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement_documents_model_180[key] = value
        })
    },
    SET_REQUIREMENT_DOCUMENTS_REFERENCE_MODEL_180(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement.requirement_documents_reference_model_180[key] = value
        })
    },
    SET_REQUIREMENT_DOCUMENTS_MODEL_190(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement_documents_model_190[key] = value
        })
    },
    SET_REQUIREMENT_DOCUMENTS_REFERENCE_MODEL_190(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement.requirement_documents_reference_model_190[key] = value
        })
    },
    SET_REQUIREMENT_DOCUMENTS_EMBARGO(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement_documents_embargo[key] = value
        })
    },
    SET_REQUIREMENT_DOCUMENTS_REFERENCE_EMBARGO(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement.requirement_documents_reference_embargo[key] = value
        })
    },
    SET_REQUIREMENT_RESPONSIBLE_PHASE(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement_responsibles_phase[key] = value.id
        })
    },
    SET_REQUIREMENT_RESPONSIBLE_PHASE_REVIEW(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement_responsibles_phase_review[key] = value.id
        })
    },
    SET_REQUIREMENT_RESPONSIBLE_PHASE_ALLEGATION(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.requirement_responsibles_phase_allegation[key] = value.id
        })
    },
    SET_VALIDATION_ERRORS(state, payload) {
        for (const key in payload) {
            state.validation_requirement[key] = '* ' + payload[key][0]
        }
    },
    CLEAR_VALIDATION(state) {
        for (const key in state.validation_requirement) {
            state.validation_requirement[key] = null
        }
    },
}

