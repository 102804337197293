/*
|--------------------------------------------------------------------------
| Base URLs (Donde se encuentra la API)
|--------------------------------------------------------------------------
| URL local
| http://api.gagpgp.local/
|
| URL clickowebs
| https://apigagpgp.clickowebs.com/
*/
export const baseUrl = 'https://api.gestorgag.com/'
