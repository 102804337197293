import axios from '@/resources/libs/axios'

export default {
	find(id, data) {
		return axios.get(`/roles/find/${id}`, {params: data})
	},
	search(data) {
		return axios.get(`/roles/list`, {params: data})
	},
	listByDepartment(data) {
		return axios.get(`/roles/list/department`, {params: data})
	},
	listPermissions(data) {
		return axios.get(`/roles/list/permissions`, {params: data})
	},
	create(data) {
		return axios.post(`/roles/create`, data)
	},
	update(data) {
		return axios.post(`/roles/update`, data)
	},
	delete(id) {
		return axios.delete(`/roles/delete/${id}`)
	},
}