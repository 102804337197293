import axios from '@/resources/libs/axios'

export default {
	find(id, data) {
		return axios.get(`/accounting/requirement/find/${id}`, {params: data})
	},
	search(data) {
		return axios.get(`/accounting/requirement/list`, {params: data})
	},
	create(data) {
		return axios.post(`/accounting/requirement/create`,  data)
	},
	update(data) {
		return axios.post(`/accounting/requirement/update`, data)
	},
	delete(id) {
		return axios.delete(`/accounting/requirement/delete/${id}`)
	},
	listAll(data) {
		return axios.get(`/accounting/requirement/list/all`, {params: data})
	},
	getTaxesTypes() {
		return axios.get(`/accounting/requirement/taxes/all`)
	},
	getDocumentsByType(id) {
		return axios.get(`/accounting/requirement/documents/types/${id}`)
	},
	generateZip(id) {
		return axios.get(`/accounting/requirement/documents/zip/${id}`)
	},
	getEpigraphsByType() {
		return axios.get(`/accounting/requirement/epigraphs/types`)
	},
	getResponsiblePhaseById(id) {
		return axios.get(`/accounting/requirement/responsible/phase/${id}`)
	},
	getRequirementsByClientAndStatus(data) {
		return axios.get(`/accounting/requirement/getByClientAndStatus`,{params: data})
	},
	updateDocuments(data)
	{
		return axios.post(`/accounting/requirement/documents`, data)
	},
	finishAllegation(id)
	{
		return axios.post(`/accounting/requirement/finishAllegation/${id}`)
	},
	deleteDocument(id) {
		return axios.delete(`/accounting/requirement/documents/delete/${id}`)
	},
	deleteDocumentAllegation(data) {
		return axios.delete(`/accounting/requirement/documents/delete/allegation/${data.id}`, {params:data})
	},
	getAllHolidays() {
		return axios.get(`/accounting/requirement/holidays`)
	},
}