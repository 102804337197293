import { INVESTMENTS_LIST, INVESTMENT_CREATE, INVESTMENT_EDIT } from '../../constants'

export default [
	{
		path: INVESTMENTS_LIST,
		name: 'investments_list',
		component: () => import('@/views/finance/investments/list/listView.vue'),
		meta: {
			pageTitle: 'Llistat d\'operacions de inversió',
			permission: 'list_investments',
		},
	},
	{
		path: INVESTMENT_CREATE,
		name: 'investments_create',
		component: () => import('@/views/finance/investments/form/formView.vue'),
		meta: {
			pageTitle: 'Crear operació de inversió',
			permission: 'create_investment',
			breadcrumb: [
				{
					text: 'Operacions de inversió',
                    to: INVESTMENTS_LIST,
                },
			],
		},
	},
	{
		path: `${INVESTMENT_EDIT}/:id`,
		name: 'investment_edit',
		component: () => import('@/views/finance/investments/form/formView.vue'),
		meta: {
			pageTitle: 'Editar operació de inversió',
			permission: 'edit_investment',
			breadcrumb: [
				{
					text: 'Operacions de inversions',
                    to: INVESTMENTS_LIST,
                },
			],
		},
	},
]
