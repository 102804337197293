import api from "@/router/api"
import store from "@/store"

export default {
	searchFormalities({commit}, payload){
		return new Promise((resolve, reject) => {
			api.formalities.search(payload)
				.then(response => {
					const {data} = response.data

					commit('SET_FORMALITIES', data.data)

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener las polizas'
					})
				})
		})
	},
	getFormality({commit}, payload){
		return new Promise((resolve, reject) => {
			api.formalities.find(payload.id, payload)
				.then(response => {

					commit('SET_FORMALITY', response.data.data)

					if (response.data.data.formality_documents)
						commit('SET_FORMALITY_DOCUMENTS', response.data.data.formality_documents)

					if (response.data.data.client){
						store.commit('client/SET_CLIENT', response.data.data.client)
					}

					if (response.data.data.client.client_personal_data){
						store.commit('client/SET_CLIENT_PERSONAL_DATA', response.data.data.client.client_personal_data)
					}

					resolve(response.data.data)
				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al obtener la poliza',
					})
				})
		})
	},
	createFormality({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.formalities.create(payload)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	updateFormality({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.formalities.update(payload)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	deleteFormality({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.formalities.delete(payload.id)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al eliminar la poliza.',
					})
				})
		})
	},
	deleteFormalityDocument({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.formalities.deleteFormalityDocument(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar el documento.',
				})
			})
		})
	},
}
