import axios from '@/resources/libs/axios'

export default {
	find(id, data) {
		return axios.get(`/trading/trading/find/${id}`, {params: data})
	},
	search(data) {
		return axios.get(`/trading/trading/list`, {params: data})
	},
	create(data) {
		return axios.post(`/trading/trading/create`,  data)
	},
	update(data) {
		return axios.post(`/trading/trading/update`, data)
	},
	delete(id) {
		return axios.delete(`/trading/trading/delete/${id}`)
	},
	getAllNotaries() {
		return axios.get(`/trading/trading/notaries/list/all`)
	},
	deleteDocument(id) {
		return axios.delete(`/trading/trading/documents/delete/${id}`)
	},
	deleteTradingModelRegister(id) {
		return axios.delete(`/trading/trading/model/delete/${id}`)
	},
	deleteTradingProperty(id) {
		return axios.delete(`/trading/trading/property/delete/${id}`)
	},
	deleteTradingInvoice(id) {
		return axios.delete(`/trading/trading/invoice/delete/${id}`)
	},
	deleteTradingRecord(id) {
		return axios.delete(`/trading/trading/record/delete/${id}`)
	},
	deleteTradingProtocol(id) {
		return axios.delete(`/trading/trading/protocol/delete/${id}`)
	},

}