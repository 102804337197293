import { updateField } from "vuex-map-fields"
import { getDefaultState } from "./policyState"
import {insurancesTypes, policiesPeriods, policiesStatus} from "@/constants/insurancePolicies"
import helpers from "@/resources/helpers/helpers"

export default {
	updateField,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	SET_POLICIES(state, payload) {
		let records = []
		let total = 0
		for (let i = 0; i < payload.length; i++) {

			records.push({
				company:  payload[i].company,
				policy_number:  payload[i].policy_number,
				client: payload[i].client.name + ' ' +payload[i].client.first_surname + ' ' +payload[i].client.second_surname,
				client_id: payload[i].client.id,
				insurance_type:  payload[i].insurance_type !== null ? insurancesTypes.find(element => element.value ===  payload[i].insurance_type).name : '',
				risk: payload[i].risk,
				date_first_effect:  payload[i].date_first_effect !== null ? helpers.formatDate(payload[i].date_first_effect) : '',
				date_effect: payload[i].date_effect !== null ? helpers.formatDate(payload[i].date_effect) : '',
				year: payload[i].date_first_effect !== null ? helpers.getYearFromDate(payload[i].date_first_effect) : '',
				date_expiration: payload[i].date_expiration !== null ? helpers.formatDate(payload[i].date_expiration) : '',
				date_payment: payload[i].date_payment  !== null ? helpers.formatDate(payload[i].date_payment) : '',
				value: payload[i].value,
				period: payload[i].period !== null ? policiesPeriods.find(element => element.value === payload[i].period).name : '',
				status:  payload[i].status !== null ? policiesStatus.find(element => element.value === payload[i].status).name : '',
				id:  payload[i].id
			})
			total += payload[i].value

		}
		records.push({
			company: 'TOTAL',
			policy_number:  '',
			client: '',
			client_id: '',
			insurance_type: '',
			risk: '',
			date_first_effect: '',
			date_effect: '',
			year:  '',
			date_expiration:  '',
			date_payment:  '',
			value: total,
			period: '',
			status: '',
			id: ''
		})
		state.policies = records
	},
	SET_POLICY(state, payload) {
		Object.entries(payload).forEach(([key, value]) => {
			state.policy[key] = value
		})

		let dates = ['date_effect', 'date_expiration', 'date_first_effect', 'date_payment']

		dates.forEach(date => {
			state.policy[date] = (payload[date]) ? helpers.formatDate(payload[date]) : null
		})


		if (payload.policy_data){

			Object.entries(payload.policy_data).forEach(([key, value]) => {
				state.policy_data[key] = value
			})

			let datesExtra = ['travel_date_start', 'travel_date_end','constitution_date']

			datesExtra.forEach(date => {
				state.policy_data[date] = (payload.policy_data[date]) ? helpers.formatDate(payload.policy_data[date]) : null
			})
		}

	},
	SET_POLICY_DATA_CAPITAL(state, payload) {
		state.policy_data_capitals = payload
	},
	SET_POLICY_DATA_TRAVEL_PERSONS(state, payload) {
		state.policy_data_travel_persons = payload
	},
	SET_POLICY_DATA_RENT_GUARANTORS(state, payload) {
		state.policy_data_rent_guarantors = payload
	},
	SET_POLICY_DATA_RENT_TENANTS(state, payload) {
		state.policy_data_rent_tenants = payload
	},
	SET_POLICY_DOCUMENTS(state, payload) {
		state.policy_documents_data.policy_documents = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_policy[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_policy) {
			state.validation_policy[key] = null
		}
	},
}

