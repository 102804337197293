import api from "@/router/api"

export default {
	searchClients({commit}, payload){
		return new Promise((resolve, reject) => {
			api.clients.search(payload)
				.then(response => {
					const {data} = response.data
					commit('SET_CLIENTS', data.data)
					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los clientes'
					})
				})
		})
	},
	getClient({commit}, payload){
		return new Promise((resolve, reject) => {
			api.clients.find(payload.id, payload)
				.then(response => {
					commit('SET_CLIENT', response.data.data)

					if(response.data.data.client_personal_data){
						commit('SET_CLIENT_PERSONAL_DATA', response.data.data.client_personal_data)
					}

					if(response.data.data.client_filter_data){
						commit('SET_CLIENT_DATA_FILTER', response.data.data.client_filter_data)
					}

					if(response.data.data.client_epigraphs){
						commit('SET_CLIENT_EPIGRAPHS', response.data.data.client_epigraphs)
					}

					if(response.data.data.client_taxes){
						commit('SET_CLIENT_TAXES', response.data.data.client_taxes)
					}

					if(response.data.data.client_documents){
						commit('SET_CLIENT_DOCUMENTS', response.data.data.client_documents)
					}

					if(response.data.data.client_models){
						commit('SET_CLIENT_MODELS', response.data.data.client_models)
					}

					resolve(response.data.data)
				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al obtener el cliente',
					})
				})
		})
	},
	createClient({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.clients.create(payload)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	updateClient({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.clients.update(payload)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	deleteClient({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.clients.delete(payload.id)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al eliminar el cliente.',
					})
				})
		})
	},
    getAllClients({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.clients.listAll(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener los clientes.',
                    })
                })
        })
    },
	updateClientFilterData({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.clients.updateDataFilter(payload)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	deleteEpigraphRecord({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.clients.deleteEpigraphRecord(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar la epigrafe.',
				})
			})
		})
	},
	getDocumentsByType({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.clients.getDocumentsByType(payload.type)
			.then(response => {
				const {data} = response.data
				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al obtener los tipos de documentos.',
				})
			})
		})

	},
	deleteClientDocument({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.clients.deleteClientDocument(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar el documento.',
				})
			})
		})
	},
	deleteClientModel({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.clients.deleteClientModel(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar el modelo.',
				})
			})
		})
	},
	deleteClientPartner({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.clients.deleteClientPartner(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar el modelo.',
				})
			})
		})
	},
	deleteClientFamilyPartner({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.clients.deleteClientFamilyPartner(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar el modelo.',
				})
			})
		})
	},
	deleteClientWorker({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.clients.deleteClientWorker(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar el modelo.',
				})
			})
		})
	},
}
