import api from "@/router/api"

export default {
    countDashboard({commit}, payload){
        return new Promise((resolve, reject) => {
            api.statistics.countDashboard()
                .then(response => {
                    const {data} = response.data
                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error las estadisticas'
                    })
                })
        })
    },
    clientsDashboard({commit}, payload){
        return new Promise((resolve, reject) => {
            api.statistics.clientsDashboard()
                .then(response => {
                    const {data} = response.data
                    resolve(data)
                })
                .catch(error => {
                    console.log(error)
                    reject({
                        message: 'Error las estadisticas'
                    })
                })
        })
    },
}