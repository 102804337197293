import ToastificationContent from "@core/components/toastification/ToastificationContent"
import Vue from "vue"

export default {
    formatDate(data) {
        let date = new Date(data)
        return this.padTo2Digits(date.getDate())  + '-' + this.padTo2Digits((date.getMonth() + 1))  + '-' +  date.getFullYear()
    },
    formatDateHour(data) {
        let date = new Date(data)
        return this.padTo2Digits(date.getDate())  + '-' + this.padTo2Digits((date.getMonth() + 1))  + '-' +  date.getFullYear() + ' ' + this.padTo2Digits(date.getHours()) + ':' + this.padTo2Digits(date.getMinutes())
    },
    formatDateString(date) {
        const dateFormated = new Date(date)

        return dateFormated.toLocaleDateString("es-ES")
    },
    formatHour(hour) {
        return hour.substring(0,5)
    },
    getYearFromDate(data) {
        let date = new Date(data)
        return date.getFullYear()
    },
    getHourFromDate(data) {
        let date = new Date(data)
        return date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0')
    },
    resetVariable(variable){
        for (const key in variable) {
            variable[key] = null
        }
    },
    showToast(title, icon, variant){
        Vue.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
                title: title,
                icon: icon,
                variant: variant,
            },
        })
    },
    padTo2Digits(num) {
        return String(num).padStart(2, '0')
    }
}
