import axios from '@/resources/libs/axios'

export default {
	find(id, data) {
		return axios.get(`/clients/find/${id}`, {params: data})
	},
	search(data) {
		return axios.get(`/clients/list`, {params: data})
	},
	create(data) {
		return axios.post(`/clients/create`, data)
	},
	update(data) {
		return axios.post(`/clients/update`, data)
	},
	updateDataFilter(data) {
		return axios.post(`/clients/update/data-filter`, data)
	},
	deleteEpigraphRecord(id) {
		return axios.delete(`/clients/delete/epigraph/${id}`)
	},
	delete(id) {
		return axios.delete(`/clients/delete/${id}`)
	},
    listAll(data) {
        return axios.get(`/clients/list/all`, {params: data})
    },
	getDocumentsByType(type) {
		return axios.get(`/clients/documents/types/${type}`)
	},
	deleteClientDocument(id) {
		return axios.delete(`/clients/documents/delete/${id}`)
	},
	deleteClientModel(id) {
		return axios.delete(`/clients/model/delete/${id}`)
	},
	deleteClientPartner(id) {
		return axios.delete(`/clients/partner/delete/${id}`)
	},
	deleteClientFamilyPartner(id) {
		return axios.delete(`/clients/partner/family/delete/${id}`)
	},
	deleteClientWorker(id) {
		return axios.delete(`/clients/worker/delete/${id}`)
	},
}