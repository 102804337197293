import api from "@/router/api"
import store from "@/store"

export default {
	searchInvestments({commit}, payload){
		return new Promise((resolve, reject) => {
			api.investments.search(payload)
				.then(response => {
					const {data} = response.data

					commit('SET_INVESTMENTS', data.data)

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener las inversiones'
					})
				})
		})
	},
	getInvestment({commit}, payload){
		return new Promise((resolve, reject) => {
			api.investments.find(payload.id, payload)
				.then(response => {
					commit('SET_INVESTMENT', response.data.data)

					if(response.data.data.documents){
						commit('SET_INVESTMENT_DOCUMENTS', response.data.data.documents)
					}

					if (response.data.data.client){
						store.commit('client/SET_CLIENT', response.data.data.client)
					}

					if (response.data.data.client.client_personal_data){
						store.commit('client/SET_CLIENT_PERSONAL_DATA', response.data.data.client.client_personal_data)
					}
					resolve(response.data.data)
				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al obtener la inversión',
					})
				})
		})
	},
	createInvestment({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.investments.create(payload)
				.then(response => {
					const {data} = response.data
					commit('SET_INVESTMENT', response.data.data)
					resolve(data)

				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	updateInvestment({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.investments.update(payload)
				.then(response => {
					const {data} = response.data
					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	deleteInvestment({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.investments.delete(payload.id)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al eliminar la inversion.',
					})
				})
		})
	},
	deleteInvestmentDocument({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.investments.deleteInvestmentDocument(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar el documento.',
				})
			})
		})
	},
}
