import axios from '@/resources/libs/axios'

export default {
	find(id, data) {
		return axios.get(`/finance/investment/find/${id}`, {params: data})
	},
	search(data) {
		return axios.get(`/finance/investment/list`, {params: data})
	},
	create(data) {
		return axios.post(`/finance/investment/create`,  data)
	},
	update(data) {
		return axios.post(`/finance/investment/update`, data)
	},
	delete(id) {
		return axios.delete(`/finance/investment/delete/${id}`)
	},
	listAll(data) {
		return axios.get(`/finance/investment/list/all`, {params: data})
	},
	updateDocuments(data)
	{
		return axios.post(`/finance/investment/documents`, data)
	},
	deleteInvestmentDocument(id) {
		return axios.delete(`/finance/investment/documents/delete/${id}`)
	},
}