import { REQUIREMENTS_LIST, REQUIREMENTS_CREATE, REQUIREMENTS_EDIT } from '../../constants'

export default [
	{
		path: REQUIREMENTS_LIST,
		name: 'requirements_list',
		component: () => import('@/views/accounting/requirements/list/listView.vue'),
		meta: {
			pageTitle: 'Llistat requeriments',
			permission: 'list_requirements',
			breadcrumb: [
				{
					text: 'Llistat requeriments',
					active: true,
				},
			],
		},
	},
	{
		path: REQUIREMENTS_CREATE,
		name: 'requirements_create',
		component: () => import('@/views/accounting/requirements/form/formView.vue'),
		meta: {
			pageTitle: 'Crear requeriment',
			permission: 'create_requirements',
			breadcrumb: [
				{
					text: 'Requeriments',
                    to: REQUIREMENTS_LIST,
                },
				{
					text: 'Crear requeriment',
					active: true,
				},
			],
		},
	},
	{
		path: `${REQUIREMENTS_EDIT}/:id`,
		name: 'requirements_edit',
		component: () => import('@/views/accounting/requirements/form/formView.vue'),
		meta: {
			pageTitle: 'Editar requeriment',
			permission: 'edit_requirements',
			breadcrumb: [
				{
					text: 'Requeriments',
                    to: REQUIREMENTS_LIST,
                },
			],
		},
	},
]
