export const getDefaultState = () => {
    return {
        investments: [],
        investment: {
            id: null,
            client_id: null,
            type: null,
            financing_entity: null,
            investment_client_type: null,
            investment_type: null,
            entry_date: null,
            date_expiration: null,
            amount: null,
            second_holder_dni: null,
            second_holder_name: null,
            documents: [],
            status:'in_progress'
        },


        finance_data_client: {
            postal_code: null,
            postal_address: null,
            fiscal_address: null,
            fiscal_code: null,
            dni_cif: null,
            client_id: null,
        },

        validation_investment: {
            id: null,
            client_id: null,
            type: null,
            financing_entity: null,
            investment_client_type: null,
            investment_type: null,
            entry_date: null,
            date_expiration: null,
            document: null,
            amount: null,
            second_holder_dni: null,
            second_holder_name: null,
            status:null,
        },

        validation_finance_data_client: {
            postal_code: null,
            postal_address: null,
            fiscal_address: null,
            fiscal_code: null,
            dni_cif: null,
            client_id: null,
        },
    }
}

export default getDefaultState()
