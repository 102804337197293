import { SCRIPTURES_LIST, SCRIPTURES_CREATE, SCRIPTURES_EDIT } from '../../constants'

export default [
	{
		path: SCRIPTURES_LIST,
		name: 'scriptures_list',
		component: () => import('@/views/trading/scriptures/list/listView.vue'),
		meta: {
			pageTitle: 'Llistat de tràmits d\'escriptura',
			permission: 'list_scriptures',
			breadcrumb: [
				{
					text: 'Llistat de tràmits d\'escriptura',
					active: true,
				},
			],
		},
	},
	{
		path: SCRIPTURES_CREATE,
		name: 'scriptures_create',
		component: () => import('@/views/trading/scriptures/form/formView.vue'),
		meta: {
			pageTitle: 'Crear tràmit d\'escriptura',
			permission: 'create_scripture',
			breadcrumb: [
				{
					text: 'Tràmits d\'escriptures',
                    to: SCRIPTURES_LIST,
                },
				{
					text: 'Crear tràmit d\'escriptura',
					active: true,
				},
			],
		},
	},
	{
		path: `${SCRIPTURES_EDIT}/:id`,
		name: 'scriptures_edit',
		component: () => import('@/views/trading/scriptures/form/formView.vue'),
		meta: {
			pageTitle: 'Editar tràmit d\'escriptura',
			permission: 'edit_scripture',
			breadcrumb: [
				{
					text: 'Tràmits d\'escriptures',
                    to: SCRIPTURES_LIST,
                },
			],
		},
	},
]
