import axios from '@/resources/libs/axios'

export default {
	find(id, data) {
		return axios.get(`/labor/formality/find/${id}`, {params: data})
	},
	search(data) {
		return axios.get(`/labor/formality/list`, {params: data})
	},
	create(data) {
		return axios.post(`/labor/formality/create`,  data)
	},
	update(data) {
		return axios.post(`/labor/formality/update`, data)
	},
	delete(id) {
		return axios.delete(`/labor/formality/delete/${id}`)
	},
	listAll(data) {
		return axios.get(`/labor/formality/list/all`, {params: data})
	},
	deleteFormalityDocument(id) {
		return axios.delete(`/labor/formality/documents/delete/${id}`)
	},
}