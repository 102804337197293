export const getDefaultState = () => {
    return {
        policies: [],
        policy_data_capitals: [],
        policy_data_travel_persons: [],
        policy_data_rent_guarantors: [],
        policy_data_rent_tenants: [],
        policy_data_rent_tenants_erased: [],

        policy: {
            id: null,
            client_id: null,
            insurance_type: null,
            company: null,
            policy_number: null,
            risk: null,
            date_first_effect: null,
            date_effect: null,
            date_payment:null,
            date_expiration:null,
            value:null,
            period:null,
            status:'in_progress'
        },

        policy_documents_data : {
            policy_documents: [],
            policy_documents_erased: [],
        },


        policy_data: {
            dni: null,
            content_capital: null,
            mainland_capital: null,
            content_value: null,
            risk_address: null,
            cadastral_reference: null,
            security_measures:null,
            company_data:null,
            activity:null,
            agreement: null,
            workers_number: null,
            billing_volume: null,
            capitals: null,
            franchise:null,
            taker_data:null,
            rent_import: null,
            dni_owner: null,
            dni_tenant: null,
            guarantees: null,
            description_note: null, //
            covered_risk: null, //
            travel_route: null,
            travel_passengers_number: null,
            travel_date_start: null,
            travel_date_end: null,
            modality: null,
            account_number: null,
            value: null,
            entity: null,
            constitution_date: null,
            rent_location: null,
            contract_house_file: null,
            contract_house: null,
            name_business: null,
            company_questions_file: null,
            company_questions: null
        },

        validation_policy: {
            name: null,
            client_id: null,
            insurance_type: null,
            company: null,
            policy_number: null,
            risk: null,
            date_first_effect: null,
            date_effect: null,
            date_payment:null,
            date_expiration:null,
            value:null,
            period:null,
            status:null,
        },

        validation_policy_data: {
            dni: null,
            content_capital: null,
            mainland_capital: null,
            content_value: null,
            risk_address: null,
            cadastral_reference: null,
            security_measures:null,
            company_data:null,
            activity:null,
            agreement: null,
            workers_number:null,
            billing_volume: null,
            capitals: null,
            franchise: null,
            taker_data: null,
            rent_import: null,
            dni_owner: null,
            dni_tenant: null,
            guarantees: null,
            name_business: null
        },
    }
}

export default getDefaultState()
