import { RESPONSIBLE_LIST } from '../constants'

export default [
    {
        path: RESPONSIBLE_LIST,
        name: 'responsible_list',
        component: () => import('@/views/responsible/list/listView.vue'),
        meta: {
            pageTitle: 'Responsables',
            permission: 'responsible_list',
            breadcrumb: [
                {
                    text: 'Responsables',
                    active: true,
                },
            ],
        },
    },
]
