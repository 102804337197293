import api from "@/router/api"
import store from "@/store"

export default {
	searchPolicies({commit}, payload){
		return new Promise((resolve, reject) => {
			api.insurancePolicies.search(payload)
				.then(response => {
					const {data} = response.data

					commit('SET_POLICIES', data.data)

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener las polizas'
					})
				})
		})
	},
	getPolicy({commit}, payload){
		return new Promise((resolve, reject) => {
			api.insurancePolicies.find(payload.id, payload)
				.then(response => {
					commit('SET_POLICY', response.data.data)

					if (response.data.data.policy_data_capitals)
						commit('SET_POLICY_DATA_CAPITAL', response.data.data.policy_data_capitals)

					if (response.data.data.policy_data_travel_persons)
						commit('SET_POLICY_DATA_TRAVEL_PERSONS', response.data.data.policy_data_travel_persons)

					if (response.data.data.policy_data_rent_guarantors)
						commit('SET_POLICY_DATA_RENT_GUARANTORS', response.data.data.policy_data_rent_guarantors)

					if (response.data.data.policy_data_rent_tenants)
						commit('SET_POLICY_DATA_RENT_TENANTS', response.data.data.policy_data_rent_tenants)

					if (response.data.data.policy_documents)
						commit('SET_POLICY_DOCUMENTS', response.data.data.policy_documents)

					if (response.data.data.client){
						store.commit('client/SET_CLIENT', response.data.data.client)
					}

					if (response.data.data.client.client_personal_data){
						store.commit('client/SET_CLIENT_PERSONAL_DATA', response.data.data.client.client_personal_data)
					}

					resolve(response.data.data)
				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al obtener la poliza',
					})
				})
		})
	},
	createPolicy({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.insurancePolicies.create(payload)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	updatePolicy({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.insurancePolicies.update(payload)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	deletePolicy({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.insurancePolicies.delete(payload.id)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al eliminar la poliza.',
					})
				})
		})
	},
    getAllPolicies({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.insurancePolicies.listAll(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener las polizas.',
                    })
                })
        })
    },
	updatePolicyDocuments({commit, dispatch}, payload){

		return new Promise((resolve, reject) => {
			api.insurancePolicies.updateDocuments(payload.data)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(error => {
				const { data, status} = error.response
				const { message: title } = data
				let errors = ''

				if(status === 422){
					data.errors.type = payload.type

					for (const key in data.errors){
						if (Array.isArray(data.errors[key])) {
							errors += '* '+data.errors[key][0] + '\n'
						}
					}
				}
				console.log(errors)
				reject({title, status, errors})
			})
		})
	},

	getAllPoliciesClient({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.insurancePolicies.listAllClientsPolicies(payload.id,payload)
			.then(response => {
				const {data} = response.data
				commit('SET_POLICIES_CLIENT', data.data[0].insurance_policies)
				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al obtener las polizas.',
				})
			})
		})
	},
}
