import api from "@/router/api"
import store from "@/store"

export default {
	searchScriptures({commit}, payload){
		return new Promise((resolve, reject) => {
			api.scriptures.search(payload)
				.then(response => {
					const {data} = response.data

					commit('SET_SCRIPTURES', data.data)

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener las escrituras'
					})
				})
		})
	},
	deleteScripture({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.scriptures.delete(payload.id)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar la escritura.',
				})
			})
		})
	},
	getAllNotaries({commit}) {
		return new Promise((resolve, reject) => {
			api.scriptures.getAllNotaries()
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al obtener notarios',
				})
			})
		})
	},
	getScripture({commit}, payload){
		return new Promise((resolve, reject) => {
			api.scriptures.find(payload.id, payload)
				.then(response => {
					commit('SET_SCRIPTURE', response.data.data)
					resolve(response.data.data)

					if(response.data.data.properties){
						commit('SET_SCRIPTURES_PROPERTIES', response.data.data.properties)
					}

					if(response.data.data.invoices){
						commit('SET_SCRIPTURES_INVOICES', response.data.data.invoices)
					}

					if (response.data.data.client){
						store.commit('client/SET_CLIENT', response.data.data.client)
					}

					if (response.data.data.client.client_personal_data){
						store.commit('client/SET_CLIENT_PERSONAL_DATA', response.data.data.client.client_personal_data)
					}

					if (response.data.data.client.client_filter_data){
						store.commit('client/SET_CLIENT_DATA_FILTER', response.data.data.client.client_filter_data)
					}

					if (response.data.data.client.client_models){
						store.commit('client/SET_CLIENT_MODELS', response.data.data.client.client_models)
					}

				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al obtener el cliente',
					})
				})
		})
	},
	createScripture({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.scriptures.create(payload)
				.then(response => {
					const {data} = response.data

					commit('SET_SCRIPTURE', response.data.data)

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	updateScripture({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.scriptures.update(payload)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	deleteScriptureModelRegister({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.scriptures.deleteScriptureModelRegister(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar la financiacion.',
				})
			})
		})
	},
	deleteScriptureInvoice({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.scriptures.deleteScriptureInvoice(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar el documento.',
				})
			})
		})
	},
	deleteScriptureProperty({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.scriptures.deleteScriptureProperty(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar la propriedad.',
				})
			})
		})
	},

}
