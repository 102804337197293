import api from "@/router/api"
import store from "@/store"

export default {
    searchFinancings({commit}, payload){
        return new Promise((resolve, reject) => {
            api.financing.search(payload)
            .then(response => {
                const {data} = response.data

                commit('SET_FINANCINGS', data.data)

                resolve(data)
            })
            .catch(() => {
                reject({
                    message: 'Error al obtener las financiaciones'
                })
            })
        })
    },
    getFinancing({commit}, payload){
        return new Promise((resolve, reject) => {
            api.financing.find(payload.id, payload)
            .then(response => {
                commit('SET_FINANCING', response.data.data)

                if(response.data.data.financing_documents){
                    commit('SET_FINANCING_DOCUMENTS', response.data.data.financing_documents)
                }
                if(response.data.data.financing_holders){
                    commit('SET_FINANCING_HOLDERS', response.data.data.financing_holders)
                }

                if (response.data.data.client){
                    store.commit('client/SET_CLIENT', response.data.data.client)
                }

                if (response.data.data.client.client_personal_data){
                    store.commit('client/SET_CLIENT_PERSONAL_DATA', response.data.data.client.client_personal_data)
                }

                if (response.data.data.client.client_partners){
                    store.commit('client/SET_CLIENT_PARTNERS', response.data.data.client.client_partners)
                }
                if (response.data.data.client.client_documents){
                    store.commit('client/SET_CLIENT_DOCUMENTS', response.data.data.client.client_documents)
                }
                resolve(response.data.data)
            })
            .catch(error => {
                reject({
                    message: 'Ocurrió un problema al obtener la financiacion',
                })
            })
        })
    },
    createFinancing({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.financing.create(payload)
            .then(response => {
                const {data} = response.data
                commit('SET_FINANCING', response.data.data)
                resolve(data)
            })
            .catch(error => {
                const { data, status} = error.response
                const { message: title } = data

                if(status === 422){
                    commit('SET_VALIDATION_ERRORS', data.errors)
                }
                reject({title, status})
            })
        })
    },
    updateFinancing({commit}, payload){
        commit('CLEAR_VALIDATION')
        return new Promise((resolve, reject) => {
            api.financing.update(payload)
            .then(response => {
                const {data} = response.data
                resolve(data)
            })
            .catch(error => {
                const { data, status} = error.response
                const { message: title } = data

                if(status === 422){
                    commit('SET_VALIDATION_ERRORS', data.errors)
                }
                reject({title, status})
            })
        })
    },
    deleteFinancing({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.financing.delete(payload.id)
            .then(response => {
                const {data} = response.data

                resolve(data)
            })
            .catch(() => {
                reject({
                    message: 'Error al eliminar la financiacion.',
                })
            })
        })
    },
    deleteFinancingDocument({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.financing.deleteDocument(payload)
            .then(response => {
                const {data} = response.data

                resolve(data)
            })
            .catch(() => {
                reject({
                    message: 'Error al eliminar la financiacion.',
                })
            })
        })
    },
    getDocumentsByCategory({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.financing.getDocumentsByCategory(payload.category)
            .then(response => {
                const {data} = response.data

                resolve(data)
            })
            .catch(() => {
                reject({
                    message: 'Error al obtener los tipos de documentos.',
                })
            })
        })

    },
    deleteFinancingHolder({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.financing.deleteFinancingHolder(payload)
            .then(response => {
                const {data} = response.data

                resolve(data)
            })
            .catch(() => {
                reject({
                    message: 'Error al eliminar el modelo.',
                })
            })
        })
    },
}
