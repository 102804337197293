import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./tradingState"
import helpers from "@/resources/helpers/helpers"

export default {
    updateField,
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
    SET_TRADINGS(state, payload) {
        state.tradings = payload
    },
    SET_TRADING(state, payload) {

        Object.entries(payload).forEach(([key, value]) => {
            state.trading[key] = value
        })
        let dates = ['signature_date', 'accrual_date', 'delivery_date', 'limit_date', 'prescription_date', 'capital_gain_settlement_date',
        'capital_gain_signature_date','capital_gain_prescription_date', 'capital_gain_out_of_date', 'liquidation_prescription_date',
        'liquidation_signature_date' ]

        dates.forEach(date => {
            state.trading[date] = (payload[date]) ? helpers.formatDate(payload[date]) : null
        })

    },
    SET_VALIDATION_ERRORS(state, payload) {
        for (const key in payload) {
            state.validation_trading[key] = '* ' + payload[key][0]
        }
    },
    SET_TRADING_PROPERTIES(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.trading.properties[key] = value
            state.trading.properties[key].date_entry = state.trading.properties[key].date_entry ? helpers.formatDate(state.trading.properties[key].date_entry) : null
            state.trading.properties[key].date_exit = state.trading.properties[key].date_exit ? helpers.formatDate(state.trading.properties[key].date_exit) : null
        })

    },
    SET_TRADING_RECORDS(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.trading.tradings_records[key] = value
            state.trading.tradings_records[key].date_entry = state.trading.tradings_records[key].date_entry ? helpers.formatDate(state.trading.tradings_records[key].date_entry) : null
            state.trading.tradings_records[key].date_exit = state.trading.tradings_records[key].date_exit ? helpers.formatDate(state.trading.tradings_records[key].date_exit) : null
        })

    },
    SET_TRADING_INVOICES(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.trading.invoices[key] = value
        })

    },
    SET_TRADING_PROTOCOLS(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.trading.concepts_protocols[key] = value
        })

    },
    CLEAR_VALIDATION(state) {
        for (const key in state.validation_trading) {
            state.validation_trading[key] = null
        }
    },
}

