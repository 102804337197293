import api from "@/router/api"
import store from "@/store"

export default {
	searchRequirements({commit}, payload){
		return new Promise((resolve, reject) => {
			api.requirements.search(payload)
				.then(response => {
					const {data} = response.data

					commit('SET_REQUIREMENTS', data.data)

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los requerimientos'
					})
				})
		})
	},
	getRequirement({commit}, payload){
		return new Promise((resolve, reject) => {
			api.requirements.find(payload.id, payload)
				.then(response => {
					commit('SET_REQUIREMENT', response.data.data)
					resolve(response.data.data)

					if(response.data.data.taxes_types){
						commit('SET_REQUIREMENT_TAXES_TYPES', response.data.data.taxes_types)
					}

					if(response.data.data.allegations){
						commit('SET_REQUIREMENT_ALLEGATIONS', response.data.data.allegations)
					}

					if(response.data.data.historical){
						commit('SET_REQUIREMENT_HISTORICAL', response.data.data.historical)
					}

					if(response.data.data.requirement_reference){
						commit('SET_REQUIREMENT_REFERENCE', response.data.data.requirement_reference)
					}

					if(response.data.data.documents_requirement){
						commit('SET_REQUIREMENT_DOCUMENTS', response.data.data.documents_requirement)
					}
					if(response.data.data.documents_i_r_p_f){
						commit('SET_REQUIREMENT_DOCUMENTS_IRPF', response.data.data.documents_i_r_p_f)
					}
					if(response.data.data.documents_i_v_a){
						commit('SET_REQUIREMENT_DOCUMENTS_IVA', response.data.data.documents_i_v_a)
					}
					if(response.data.data.documents_i_s){
						commit('SET_REQUIREMENT_DOCUMENTS_IS', response.data.data.documents_i_s)
					}
					if(response.data.data.documents_cens){
						commit('SET_REQUIREMENT_DOCUMENTS_CENS', response.data.data.documents_cens)
					}
					if(response.data.data.documents_model347){
						commit('SET_REQUIREMENT_DOCUMENTS_MODEL_347', response.data.data.documents_model347)
					}
					if(response.data.data.documents_model180){
						commit('SET_REQUIREMENT_DOCUMENTS_MODEL_180', response.data.data.documents_model180)
					}
					if(response.data.data.documents_model190){
						commit('SET_REQUIREMENT_DOCUMENTS_MODEL_190', response.data.data.documents_model190)
					}
					if(response.data.data.documents_embargo){
						commit('SET_REQUIREMENT_DOCUMENTS_EMBARGO', response.data.data.documents_embargo)
					}

					if (response.data.data.client){
						store.commit('client/SET_CLIENT', response.data.data.client)
					}

					if (response.data.data.client.client_filter_data){
						store.commit('client/SET_CLIENT_DATA_FILTER', response.data.data.client.client_filter_data)
					}

					if (response.data.data.client.client_epigraphs){
						store.commit('client/SET_CLIENT_EPIGRAPHS', response.data.data.client.client_epigraphs)
					}

					if (response.data.data.client.client_partners){
						store.commit('client/SET_CLIENT_PARTNERS', response.data.data.client.client_partners)
					}
				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al obtener el cliente',
					})
				})
		})
	},
	getRequirementReference({commit}, payload){
		return new Promise((resolve, reject) => {
			api.requirements.find(payload.id, payload)
				.then(response => {
					commit('SET_REQUIREMENT_REFERENCE', response.data.data)

					commit('SET_REQUIREMENT_FROM_REFERENCE', response.data.data)

					if(response.data.data.taxes_types){
						commit('SET_REQUIREMENT_TAXES_TYPES', response.data.data.taxes_types)
					}

					if(response.data.data.documents_i_r_p_f){
						commit('SET_REQUIREMENT_DOCUMENTS_REFERENCE_IRPF', response.data.data.documents_i_r_p_f)
					}
					if(response.data.data.documents_iva){
						commit('SET_REQUIREMENT_DOCUMENTS_REFERENCE_IVA', response.data.data.documents_iva)
					}
					if(response.data.data.documents_i_s){
						commit('SET_REQUIREMENT_DOCUMENTS_REFERENCE_IS', response.data.data.documents_i_s)
					}
					if(response.data.data.documents_cens){
						commit('SET_REQUIREMENT_DOCUMENTS_REFERENCE_CENS', response.data.data.documents_cens)
					}
					if(response.data.data.documents_model347){
						commit('SET_REQUIREMENT_DOCUMENTS_REFERENCE_MODEL_347', response.data.data.documents_model347)
					}
					if(response.data.data.documents_model180){
						commit('SET_REQUIREMENT_DOCUMENTS_REFERENCE_MODEL_180', response.data.data.documents_model180)
					}
					if(response.data.data.documents_model190){
						commit('SET_REQUIREMENT_DOCUMENTS_REFERENCE_MODEL_190', response.data.data.documents_model190)
					}
					if(response.data.data.documents_embargo){
						commit('SET_REQUIREMENT_DOCUMENTS_REFERENCE_EMBARGO', response.data.data.documents_embargo)
					}

					resolve(response.data.data)

				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al obtener el cliente',
					})
				})
		})
	},
	createRequirement({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.requirements.create(payload.data)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	updateRequirement({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.requirements.update(payload.data)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	deleteRequirement({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.requirements.delete(payload.id)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al eliminar el requerimiento.',
					})
				})
		})
	},
	getTaxesTypes({commit}) {
		return new Promise((resolve, reject) => {
			api.requirements.getTaxesTypes()
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los tipos de impuestos.',
					})
				})
		})

	},
	getAllHolidays({commit}) {
		return new Promise((resolve, reject) => {
			api.requirements.getAllHolidays()
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los tipos de impuestos.',
					})
				})
		})

	},
	getEpigraphs({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.requirements.getEpigraphsByType(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al obtener los tipos de epigrafes.',
				})
			})
		})

	},
	getResponsiblePhase({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.requirements.getResponsiblePhaseById(payload.id)
			.then(response => {
				const {data} = response.data

				if(response.data.data.module_phases.users){
					commit('SET_REQUIREMENT_RESPONSIBLE_PHASE', response.data.data.module_phases.users)
				}

				if(response.data.data.name === 'review'){
					commit('SET_REQUIREMENT_RESPONSIBLE_PHASE_REVIEW', response.data.data.module_phases.users)
				}

				if(response.data.data.name === 'allegation' || response.data.data.name === 'modifying_allegation'){
					commit('SET_REQUIREMENT_RESPONSIBLE_PHASE_ALLEGATION', response.data.data.module_phases.users)
				}
				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al obtener los responsables de la fase.',
				})
			})
		})

	},
	getDocumentsByType({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.requirements.getDocumentsByType(payload.id)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al obtener los tipos de documentos.',
				})
			})
		})

	},
	generateZip({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.requirements.generateZip(payload.id)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al obtener los tipos de documentos.',
				})
			})
		})

	},
	deleteRequirementDocument({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.requirements.deleteDocument(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar la financiacion.',
				})
			})
		})
	},
	deleteRequirementDocumentAllegation({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.requirements.deleteDocumentAllegation(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar la financiacion.',
				})
			})
		})
	},
	finishAllegation({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.requirements.finishAllegation(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al finalitzar la al·legació.',
				})
			})
		})
	},
	getRequirementsByClientAndStatus({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.requirements.getRequirementsByClientAndStatus(payload)
			.then(response => {
				const {data} = response.data
				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al obtener los requerimientos.',
				})
			})
		})
	},
}
