import state from './financingState'
import actions from './financingActions'
import mutations from './financingMutations'
import getters from './financingGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
