import state from './inheritanceState'
import actions from './inheritanceActions'
import mutations from './inheritanceMutations'
import getters from './inheritanceGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
