import axios from '@/resources/libs/axios'

export default {
	find(id, data) {
		return axios.get(`/insurance/policy/find/${id}`, {params: data})
	},
	search(data) {
		return axios.get(`/insurance/policy/list`, {params: data})
	},
	create(data) {
		return axios.post(`/insurance/policy/create`,  data)
	},
	update(data) {
		return axios.post(`/insurance/policy/update`, data)
	},
	delete(id) {
		return axios.delete(`/insurance/policy/delete/${id}`)
	},
	listAll(data) {
		return axios.get(`/insurance/policy/list/all`, {params: data})
	},
	updateDocuments(data)
	{
		return axios.post(`/insurance/policy/documents`, data)
	}
}