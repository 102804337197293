export const getDefaultState = () => {
    return {
        clients: [],
        client_personal_data: {
            fiscal_address: null,
            fiscal_code: null,
            dni: null,
            birthday: null,
            location: null,
            province: null,
            client_id: null,
        },

        client: {
            id: null,
            name: null,
            first_surname: null,
            second_surname: null,
            nif_cif: null,
            address: null,
            postal_code: null,
            city: null,
            phone: null,
            email: null,
            type_economic_activity: null,
            type: null,
            active: null,
            user_id: null,

            client_personal_data: {
                fiscal_address: null,
                fiscal_code: null,
                dni: null,
                birthday: null,
                location: null,
                province: null,
                client_id: null,
            },
            client_partners: [],
            client_family_partners: [],
            client_workers: [],
            client_epigraphs: [],
            client_documents: [],
            capital_gains: [],
            open_models: [],
            models_records_036: [],
            models_records_037: [],
            models_records_210: [],
            models_records_600: [],
            models_records_650: [],
            models_records_652: [],
            models_records_653: [],
            models_records_660: [],
            models_records_902: [],
            models_records_open_models: [],
            client_filter_data: {
                id: null,
                incorporated_company: 0,
                is_registered:0,
                already_client:0,
                first_company_name: null,
                second_company_name: null,
                third_company_name: null,
                activity_type: null,
                how_you_meet_us: null,
                constitution_deed: null,
                file_constitution_deed: null,
                powers_in_force: null,
                file_powers_in_force: null,
                act_real_ownership: null,
                file_act_real_ownership: null,
                corporate_autonomous: null,
                corporate_autonomous_leave: null,
                observations: null,
                capitalize_unemployment: null,
                capitalize_yourself: null,
                proforma_invoice: null,
                file_proforma_invoice: null,
                precontract_rent: null,
                file_precontract_rent: null,
                unemployed_right: null,
                unemployed_applied: null,
                unemployed_granted: null,
                has_cerfiticate: null,
                has_collabs: null,
                has_workers: null,
                objective_by_modules: 0,
                direct_simple: 0,
                rent_place: 0,
                property_place: 0,
                feasible_town_hall: null,
                file_feasible_town_hall: null,
                harmless_activity: null,
                file_harmless_activity: null,
                engineering_documentation: null,
                file_engineering_documentation: null,
                six_months_registered_activity: null,
                file_six_months_registered_activity: null,
                file_rental_contract: null,
                adaptation_works: null,
                file_adaptation_works: null,
                works_invoices: null,
                file_works_invoices: null,
                town_hall_request: null,
                file_town_hall_request: null,
                file_model_036: null,
                file_model_037: null,
                file_self_employed: null,
                down_self_employed: null,
                self_employee: null,
                epigraph_type: null,
                address: null,
                postal_code: null,
                construction_meters: null,
                register_reference: null,
                selected_place: null,
                model_monthly_111: 0,
                model_monthly_115: 0,
                model_monthly_123: 0,
                model_monthly_340: 0,
                model_monthly_303: 0,
                model_monthly_349: 0,
                model_trimester_111: 0,
                model_trimester_115: 0,
                model_trimester_123: 0,
                model_trimester_130: 0,
                model_trimester_131: 0,
                model_trimester_303: 0,
                model_trimester_349: 0,
                model_trimester_202: 0,
                model_annual_180: 0,
                model_annual_182: 0,
                model_annual_190: 0,
                model_annual_193: 0,
                model_annual_347: 0,
                model_annual_390: 0,
                model_annual_200: 0,
                model_annual_210: 0,
                model_annual_720: 0,
                ccc_company: null,
                autonomous_cessation: null,
                autonomous_cessation_itself: null,
                unemployment_capitalization: null,
                home_employee: null,
                home_employee_account: null,
                client_dni: null,
                worker_partners: null,
                client_id: null,
            },
        },

        validation_client: {
            name: null,
            first_surname: null,
            second_surname: null,
            nif_cif: null,
            email: null,
            phone: null,
            address: null,
            city: null,
            postal_code: null,
            user_id: null,
            activity_id: null,
        },

        validation_extra_data_client: {
            fiscal_address: null,
            fiscal_code: null,
            dni: null,
            birthday: null,
            client_id: null,
        },

    }
}

export default getDefaultState()
