import api from "@/router/api"
import store from "@/store"

export default {
	searchTradings({commit}, payload){
		return new Promise((resolve, reject) => {
			api.trading.search(payload)
				.then(response => {
					const {data} = response.data

					commit('SET_TRADINGS', data.data)

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los tramites'
					})
				})
		})
	},
	deleteTrading({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.trading.delete(payload.id)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar el tramite.',
				})
			})
		})
	},
	getTrading({commit}, payload){
		return new Promise((resolve, reject) => {
			api.trading.find(payload.id, payload)
				.then(response => {
					commit('SET_TRADING', response.data.data)
					resolve(response.data.data)


					if(response.data.data.properties){
						commit('SET_TRADING_PROPERTIES', response.data.data.properties)
					}

					if(response.data.data.tradings_records){
						commit('SET_TRADING_RECORDS', response.data.data.tradings_records)
					}

					if(response.data.data.trading_protocols){
						commit('SET_TRADING_PROTOCOLS', response.data.data.trading_protocols)
					}

					if(response.data.data.invoices){
						commit('SET_TRADING_INVOICES', response.data.data.invoices)
					}

					if (response.data.data.client){
						store.commit('client/SET_CLIENT', response.data.data.client)
					}

					if (response.data.data.client.client_personal_data){
						store.commit('client/SET_CLIENT_PERSONAL_DATA', response.data.data.client.client_personal_data)
					}

					if (response.data.data.client.client_filter_data){
						store.commit('client/SET_CLIENT_DATA_FILTER', response.data.data.client.client_filter_data)
					}

					if (response.data.data.client.client_models){
						store.commit('client/SET_CLIENT_MODELS', response.data.data.client.client_models)
					}

				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al obtener el cliente',
					})
				})
		})
	},
	createTrading({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.trading.create(payload)
				.then(response => {
					const {data} = response.data

					commit('SET_TRADING', response.data.data)

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	updateTrading({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.trading.update(payload)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	deleteTradingModelRegister({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.trading.deleteTradingModelRegister(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar la financiacion.',
				})
			})
		})
	},
	deleteTradingInvoice({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.trading.deleteTradingInvoice(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar el documento.',
				})
			})
		})
	},
	deleteTradingProperty({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.trading.deleteTradingProperty(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar la propriedad.',
				})
			})
		})
	},
	deleteTradingRecord({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.trading.deleteTradingRecord(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar la propriedad.',
				})
			})
		})
	},
	deleteTradingProtocol({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.trading.deleteTradingProtocol(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar la propriedad.',
				})
			})
		})
	},

}
