import api from "@/router/api"

export default {
    getAll({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.responsibles.getAll(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener los responsables.',
                    })
                })
        })
    },
    update({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.responsibles.update(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al actualizar los responsables.',
                    })
                })
        })
    },
}