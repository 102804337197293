import { CLIENTS_LIST, CLIENTS_CREATE, CLIENTS_EDIT } from '../constants'

export default [
	{
		path: CLIENTS_LIST,
		name: 'clients_list',
		component: () => import('@/views/clients/list/components/client/listView.vue'),
		meta: {
			pageTitle: 'Llistat clients',
			permission: 'list_clients',
			breadcrumb: [
				{
					text: 'Llistat clients',
					active: true,
				},
			],
		},
	},
	{
		path: CLIENTS_CREATE,
		name: 'clients_create',
		component: () => import('@/views/clients/form/formView.vue'),
		meta: {
			pageTitle: 'Crear client',
			permission: 'create_clients',
			breadcrumb: [
				{
					text: 'Clients',
                    to: CLIENTS_LIST,
                },
				{
					text: 'Crear client',
					active: true,
				},
			],
		},
	},
	{
		path: `${CLIENTS_EDIT}/:id`,
		name: 'clients_edit',
		props: true,
		component: () => import('@/views/clients/form/formView.vue'),
		meta: {
			pageTitle: '',
			permission: 'edit_clients',
			breadcrumb: [
				{
					text: 'Clients',
                    to: CLIENTS_LIST,
                },
				{
					text: 'Editar client',
					active: true,
				},
			],
		},
	},
]
