export const getDefaultState = () => {
    return {
        financings: [],

        financing: {
            id: null,
            type: null,
            name: null,
            financing_client_type: null,
            financing_type: null,
            financing_entity: null,
            document: null,
            document_chat_file: null,
            amount: 0,
            signed_amount: 0,
            renewal_date: null,
            expiration_date: null,
            signature_date: null,
            entry_date: null,
            status: 'in_progress',
            autonomous: 0,
            second_holder_name: null,
            second_holder_dni: null,
            client_id: null,
            documents: [],
            financing_holders: []
        },

        financing_documents_apagar: {
            dni_nie: null,
            dni_nie_file: null,
            updated_work_live: null,
            updated_work_live_file: null,
            work_contract: null,
            work_contract_file: null,
            last_three_payrolls: null,
            last_three_payrolls_file: null,
            declaration_income_last_year: null,
            declaration_income_last_year_file: null,
            rental_agreement: null,
            rental_agreement_file: null,
            last_three_rent_receipts: null,
            last_three_rent_receipt_file: null,
            earnest_money_contract: null,
            earnest_money_contract_file: null,
            bank_movements_last_6_months: null,
            bank_movements_last_6_months_file: null,
            divorce_sentence: null,
            divorce_sentence_file: null,
            simple_registry_property: null,
            simple_registry_property_file: null,
            high_iae: null,
            high_iae_file: null,
            last_three_self_employed_receipts: null,
            last_three_self_employed_receipts_file: null,
            last_four_quarterly_liquidations_VAT_IRPF: null,
            last_four_quarterly_liquidations_VAT_IRPF_file: null,
            cif_file: null,
            cif: null,
            deed_of_incorporation: null,
            deed_of_incorporation_file: null,
            powers: null,
            powers_file: null,
            last_balance: null,
            last_balance_file: null,
            vat_personal_income_tax_returns: null,
            vat_personal_income_tax_returns_file: null,
            latest_corporate_tax: null,
            latest_corporate_tax_file: null,
            three_last_receipts_debts: null,
            three_last_receipts_debts_file: null,
            bank_pool: null,
            bank_pool_file: null,
            dni_partners: null,
            dni_partners_file: null,
            others: null,
            others_file: null,
            financing_id: null,
        },

        finance_data_client: {
            postal_code: null,
            postal_address: null,
            fiscal_address: null,
            fiscal_code: null,
            dni_cif: null,
            client_id: null,
        },

        validation_financing: {
            id: null,
            type: null,
            name: null,
            financing_client_type: null,
            financing_type: null,
            financing_entity: null,
            amount: null,
            signed_amount: null,
            autonomous: null,
            renewal_date: null,
            expiration_date: null,
            signature_date: null,
            second_holder_name: null,
            second_holder_dni: null,
            client_id: null,
        },

        validation_finance_data_client: {
            postal_code: null,
            postal_address: null,
            fiscal_address: null,
            fiscal_code: null,
            dni_cif: null,
            client_id: null,
        },


    }
}

export default getDefaultState()
