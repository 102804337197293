import { TRADING_LIST, TRADING_CREATE, TRADING_EDIT } from '../../constants'

export default [
	{
		path: TRADING_LIST,
		name: 'trading_list',
		component: () => import('@/views/trading/trading/list/listView.vue'),
		meta: {
			pageTitle: 'Llistat de tràmits de mercantil',
			permission: 'list_scriptures',
			breadcrumb: [
				{
					text: 'Llistat de tràmits de mercantil',
					active: true,
				},
			],
		},
	},
	{
		path: TRADING_CREATE,
		name: 'trading_create',
		component: () => import('@/views/trading/trading/form/formView.vue'),
		meta: {
			pageTitle: 'Crear tràmit de mercantil',
			permission: 'create_scripture',
			breadcrumb: [
				{
					text: 'Tràmits de mercantil',
                    to: TRADING_LIST,
                },
				{
					text: 'Crear tràmit de mercantil',
					active: true,
				},
			],
		},
	},
	{
		path: `${TRADING_EDIT}/:id`,
		name: 'trading_edit',
		component: () => import('@/views/trading/trading/form/formView.vue'),
		meta: {
			pageTitle: 'Editar tràmit de mercantil',
			permission: 'edit_scripture',
			breadcrumb: [
				{
					text: 'Tràmits de mercantil',
                    to: TRADING_LIST,
                },
			],
		},
	},
]
