import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/services/auth/utils'
import { DASHBOARD, LOGIN } from './constants'
import ToastificationContent from "@core/components/toastification/ToastificationContent"
import store from '@/store'

import pages from './modules/pages'
import roles from './modules/roles'
import users from './modules/users'
import clients from './modules/clients'
import responsibles from './modules/responsibles'
import insurancePolicies from './modules/insurance/policies'
import investments from './modules/finance/investments'
import financing from './modules/finance/financing'
import requirements from './modules/accounting/requirements'
import scriptures from "./modules/trading/scriptures"
import inheritances from "./modules/trading/inheritances"
import trading from "./modules/trading/trading"
import labor from "./modules/labor/formalities"

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {	path: '/', redirect: {name: 'dashboard'} },
        {
            path: DASHBOARD,
            name: 'dashboard',
            component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
        },
        ...pages,
        ...roles,
        ...users,
        ...clients,
        ...financing,
        ...insurancePolicies,
        ...investments,
        ...responsibles,
        ...requirements,
        ...scriptures,
        ...inheritances,
        ...trading,
        ...labor,
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn()

    if (!isLoggedIn && to.fullPath != LOGIN) {
        return next({name: 'auth-login'})
    }

    if (to.meta.permission) {
        if (!store.getters.can(to.meta.permission)) {
            Vue.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: `No tens permisos per a accedir a aquesta pàgina.`,
                    icon: 'XSquareIcon',
                    variant: 'danger',
                },
            })

            router.push({path: DASHBOARD})
        }
    }

    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
