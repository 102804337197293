import auth from "./modules/auth"
import roles from "./modules/roles"
import users from "./modules/users"
import clients from "./modules/clients"
import notifications from "./modules/notifications"
import insurancePolicies from "./modules/insurance/policies"
import investments from "./modules/finance/investments"
import financing from "./modules/finance/financing"
import responsibles from "./modules/responsibles"
import requirements from "./modules/accounting/requirements"
import scriptures from "./modules/trading/scriptures"
import inheritances from "./modules/trading/inheritances"
import trading from "./modules/trading/trading"
import formalities from "./modules/labor/formalities"
import statistics from "./modules/statistics"

export default {
    auth,
    roles,
    users,
    clients,
    notifications,
    insurancePolicies,
    investments,
    responsibles,
    requirements,
    financing,
    scriptures,
    inheritances,
    trading,
    formalities,
    statistics
}