import Vue from 'vue'
import {ToastPlugin, ModalPlugin} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router/web'
import store from './store'
import App from './App.vue'
import money from 'v-money'


// Global Components
import './global-components'

// 3rd party plugins
import '@/resources/libs/axios'
import '@/resources/libs/acl'
import '@/resources/libs/portal-vue'
import '@/resources/libs/clipboard'
import '@/resources/libs/toastification'
import '@/resources/libs/sweet-alerts'
import '@/resources/libs/vue-select'
import '@/resources/libs/tour'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(money, {
    decimal: ',',
    thousands: '.',
    prefix: '€',
    suffix: '',
    precision: 2,
    masked: false
})


// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
