import { FINANCING_LIST, FINANCING_CREATE, FINANCING_EDIT } from '../../constants'

export default [
    {
        path: FINANCING_LIST,
        name: 'financing_list',
        component: () => import('@/views/finance/financing/list/listView.vue'),
        meta: {
            pageTitle: 'Llistat d\'operacions de finançament',
            permission: 'list_financings',
        },
    },
    {
        path: FINANCING_CREATE,
        name: 'financing_create',
        component: () => import('@/views/finance/financing/form/formView.vue'),
        meta: {
            pageTitle: 'Crear operació de finançament',
            permission: 'create_financing',
            breadcrumb: [
                {
                    text: 'Operacions de finançament',
                    to: FINANCING_LIST,
                },
            ],
        },
    },
    {
        path: `${FINANCING_EDIT}/:id`,
        name: 'financing_edit',
        component: () => import('@/views/finance/financing/form/formView.vue'),
        meta: {
            pageTitle: 'Editar operació de finançament',
            permission: 'edit_financing',
            breadcrumb: [
                {
                    text: 'Operacions de finançament',
                    to: FINANCING_LIST,
                },
            ],
        },
    },
]
