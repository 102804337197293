export const getDefaultState = () => {
    return {
        requirements: [],
        requirement_documents: [],
        requirement_documents_iva: [],
        requirement_documents_irpf: [],
        requirement_documents_is: [],
        requirement_documents_cens: [],
        requirement_documents_model_347: [],
        requirement_documents_model_180: [],
        requirement_documents_model_190: [],
        requirement_documents_embargo: [],
        requirement_responsibles_phase: [],
        requirement_responsibles_phase_review: [],
        requirement_responsibles_phase_allegation: [],
        requirement: {
            id: null,
            taxes_types: [],
            client_id: null,
            invoice: 1,
            person_in_charge: null,
            date_received_client: null,
            date_sent_allegation: null,
            date_expiration: null,
            date_expiration_added_days: null,
            added_days: 1,
            enlargement_document: null,
            enlargement_document_file: null,
            trimester: null,
            init_year: null,
            end_year: null,
            period: null,
            resolution_comments: null,
            resolution_document: null,
            resolution_document_file: null,
            authorization_document: null,
            authorization_document_file: null,
            review: null,
            date_finalization: null,
            allegations: [],
            requirement_reference: [],
            redo_allegation: 0,
            has_requirement_reference: 0,
            requirement_reference_id: null,
            requirement_documents_reference_irpf: [],
            requirement_documents_reference_iva: [],
            requirement_documents_reference_is: [],
            requirement_documents_reference_cens: [],
            requirement_documents_reference_model_347: [],
            requirement_documents_reference_model_180: [],
            requirement_documents_reference_model_190: [],
            requirement_documents_reference_embargo: [],
            requirement_historical: [],
            status: 'preparation',
        },


        validation_requirement: {
            id: null,
            taxes_types: null,
            client_id: null,
            date_received_client: null,
            date_expiration_added_days: null,
            allegation_document_file: null,
            allegation_document: null,
            allegation_sent_document_file: null,
            allegation_sent_document: null,
            date_expiration: null,
            added_days: null,
            allegation: null,
            review: null,
            description: null,
            trimester: null,
            init_year: null,
            end_year: null,
            person_in_charge: null,
            period: null,
        }
    }
}

export default getDefaultState()
