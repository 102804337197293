import state from './requirementState'
import actions from './requirementActions'
import mutations from './requirementMutations'
import getters from './requirementGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
