import { updateField } from "vuex-map-fields"
import { getDefaultState } from "./formalityState"
import helpers from "@/resources/helpers/helpers"

export default {
	updateField,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	SET_FORMALITIES(state, payload) {
		state.formalities = payload
	},
	SET_FORMALITY(state, payload) {
		Object.entries(payload).forEach(([key, value]) => {
			state.formality[key] = value
		})
	},
	SET_FORMALITY_DOCUMENTS(state, payload) {
		Object.entries(payload).forEach(([key, value]) => {
			console.log(value)
			state.formality.formality_documents[key] = value
			state.formality.formality_documents[key].date_start = state.formality.formality_documents[key].date_start ? helpers.formatDate(state.formality.formality_documents[key].date_start) : null
			state.formality.formality_documents[key].date_end = state.formality.formality_documents[key].date_end ? helpers.formatDate(state.formality.formality_documents[key].date_end) : null
		})
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation_formality[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation_formality) {
			state.validation_formality[key] = null
		}
	},
}

