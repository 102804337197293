import axios from '@/resources/libs/axios'

export default {
	find(id, data) {
		return axios.get(`/trading/inheritance/find/${id}`, {params: data})
	},
	search(data) {
		return axios.get(`/trading/inheritance/list`, {params: data})
	},
	create(data) {
		return axios.post(`/trading/inheritance/create`,  data)
	},
	update(data) {
		return axios.post(`/trading/inheritance/update`, data)
	},
	delete(id) {
		return axios.delete(`/trading/inheritance/delete/${id}`)
	},
	getAllNotaries() {
		return axios.get(`/trading/inheritance/notaries/list/all`)
	},
	deleteInheritanceDocumentCategory(id) {
		return axios.delete(`/trading/inheritance/documents/delete/${id}`)
	},
	deleteInheritanceModelRegister(id) {
		return axios.delete(`/trading/inheritance/model/delete/${id}`)
	},
	deleteInheritanceProperty(id) {
		return axios.delete(`/trading/inheritance/property/delete/${id}`)
	},
	deleteInheritanceInvoice(id) {
		return axios.delete(`/trading/inheritance/invoice/delete/${id}`)
	},
	getDocumentsByCategory(id) {
		return axios.get(`/trading/inheritance/documents/types/${id}`)
	},

}