export const getDefaultState = () => {
    return {
        inheritances: [],
        inheritance: {
            id: null,
            client_id: null,
            type: null,
            category: null,
            invoice: 1,
            signature_date: null,
            notary: null,
            notary_bill_document_file: null,
            notary_bill_document: null,
            inheritance_document_file: null,
            inheritance_document: null,
            authorization_gag_document: null,
            authorization_gag_document_file: null,
            date_of_death: null,
            resided_at: null,
            extension_request: 0,
            extension_type: 0,
            separation: 0,
            townhall: 0,
            isd: 0,
            internal_observations: null,
            protocol: null,
            accrual_date: null,
            limit_date: null,
            delivery_date: null,
            prescription_date: null,
            capital_gain_settlement_date: null,
            capital_gain_signature_date: null,
            capital_gain_prescription_date: null,
            capital_gain_out_of_date: null,
            liquidation_prescription_date: null,
            liquidation_signature_date: null,
            new_construction: 0,
            segregation: 0,
            division: 0,
            modification_register: null,
            autonomous_community: null,
            comprobation_value_register: null,
            reference_value: null,
            reference_value_document: null,
            reference_value_document_file: null,
            register_value: null,
            register_value_document: null,
            register_value_document_file: null,
            coefficient_value: null,
            coefficient_value_document: null,
            coefficient_value_document_file: null,
            minimum_value: null,
            minimum_value_document: null,
            minimum_value_document_file: null,
            observations: null,
            models_records_902: [],
            models_records_660: [],
            models_records_650: [],
            models_records_651: [],
            models_records_652: [],
            models_records_653: [],
            models_records_open_model: [],
            capital_gains: [],
            properties: [],
            invoices: [],
            inheritance_documents: [],
        },

        validation_inheritance: {
            id: null,
            client_id: null,
            type: null,
            category: null,
            signature_date: null,
            internal_observations: null,
            notary: null,
            notary_bill_document_file: null,
            notary_bill_document: null,
            inheritance_document_file: null,
            inheritance_document: null,
            protocol: null,
            accrual_date: null,
            limit_date: null,
            prescription_date: null,
            capital_gain_settlement_date: null,
            capital_gain_signature_date: null,
            capital_gain_prescription_date: null,
            capital_gain_out_of_date: null,
            capital_gain_document: null,
            capital_gain_document_file: null,
            liquidation_prescription_date: null,
            liquidation_signature_date: null,
            liquidation_document: null,
            liquidation_document_file: null,
            townhall_notification: null,
            provision_funds: null,
            new_construction: null,
            segregation: null,
            division: null,
            modification_register: null,
            autonomous_community: null,
            comprobation_value_register: null,
            reference_value: null,
            reference_value_document: null,
            register_value: null,
            register_value_document: null,
            coefficient_value: null,
            coefficient_value_document: null,
            minimum_value: null,
            minimum_value_document: null,
            observations: null
        }
    }
}

export default getDefaultState()
