export const getDefaultState = () => {
    return {
        tradings: [],
        trading: {
            id: null,
            client_id: null,
            type: null,
            category: null,
            scripture_type: null,
            invoice: 1,
            signature_date: null,
            notary: null,
            notary_bill_document_file: null,
            notary_bill_document: null,
            trading_document_file: null,
            trading_document: null,
            internal_observations: null,
            protocol: null,
            accrual_date: null,
            limit_date: null,
            delivery_date: null,
            prescription_date: null,
            liquidation_prescription_date: null,
            liquidation_signature_date: null,
            townhall_notification: null,
            provision_funds: null,
            new_construction: 0,
            segregation: 0,
            division: 0,
            modification_register: null,
            autonomous_community: null,
            comprobation_value_register: null,
            reference_value: null,
            reference_value_document: null,
            reference_value_document_file: null,
            register_value: null,
            register_value_document: null,
            register_value_document_file: null,
            coefficient_value: null,
            coefficient_value_document: null,
            coefficient_value_document_file: null,
            minimum_value: null,
            minimum_value_document: null,
            minimum_value_document_file: null,
            observations: null,
            digital_certificate_type: null,
            digital_certificate_document: null,
            digital_certificate_document_file: null,
            digital_certificate_password: null,
            concepts_protocols: [],
            tradings_records: [],
            properties: [],
            invoices: [],
        },

        validation_trading: {
            id: null,
            client_id: null,
            type: null,
            category: null,
            signature_date: null,
            internal_observations: null,
            notary: null,
            notary_bill_document_file: null,
            notary_bill_document: null,
            trading_document_file: null,
            trading_document: null,
            protocol: null,
            accrual_date: null,
            limit_date: null,
            prescription_date: null,
            capital_gain_settlement_date: null,
            capital_gain_signature_date: null,
            capital_gain_prescription_date: null,
            capital_gain_out_of_date: null,
            capital_gain_document: null,
            capital_gain_document_file: null,
            liquidation_prescription_date: null,
            liquidation_signature_date: null,
            liquidation_document: null,
            liquidation_document_file: null,
            townhall_notification: null,
            provision_funds: null,
            new_construction: null,
            segregation: null,
            division: null,
            modification_register: null,
            autonomous_community: null,
            comprobation_value_register: null,
            reference_value: null,
            reference_value_document: null,
            register_value: null,
            register_value_document: null,
            coefficient_value: null,
            coefficient_value_document: null,
            minimum_value: null,
            minimum_value_document: null,
            observations: null
        }
    }
}

export default getDefaultState()
