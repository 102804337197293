import state from './investmentState'
import actions from './investmentActions'
import mutations from './investmentMutations'
import getters from './investmentGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
