import { INHERITANCES_LIST, INHERITANCES_CREATE, INHERITANCES_EDIT } from '../../constants'

export default [
	{
		path: INHERITANCES_LIST,
		name: 'inheritances_list',
		component: () => import('@/views/trading/inheritances/list/listView.vue'),
		meta: {
			pageTitle: 'Llistat de tràmits d\'herències',
			permission: 'list_inheritances',
			breadcrumb: [
				{
					text: 'Llistat de tràmits d\'herències',
					active: true,
				},
			],
		},
	},
	{
		path: INHERITANCES_CREATE,
		name: 'inheritances_create',
		component: () => import('@/views/trading/inheritances/form/formView.vue'),
		meta: {
			pageTitle: 'Crear tràmit d\'herència',
			permission: 'create_scripture',
			breadcrumb: [
				{
					text: 'Tràmits d\'herències',
                    to: INHERITANCES_LIST,
                },
				{
					text: 'Crear tràmit d\'herència',
					active: true,
				},
			],
		},
	},
	{
		path: `${INHERITANCES_EDIT}/:id`,
		name: 'inheritances_edit',
		component: () => import('@/views/trading/inheritances/form/formView.vue'),
		meta: {
			pageTitle: 'Editar tràmit d\'herència',
			permission: 'edit_scripture',
			breadcrumb: [
				{
					text: 'Tràmits d\'herències',
                    to: INHERITANCES_LIST,
                },
			],
		},
	},
]
