import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./clientState"
import helpers from "@/resources/helpers/helpers"

export default {
    updateField,
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
    SET_CLIENTS(state, payload) {
        state.clients = payload
    },
    SET_CLIENT(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            if (key != 'client_personal_data' && key != 'client_filter_data') {
                state.client[key] = value
            }
        })
    },
    SET_CLIENT_PERSONAL_DATA(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.client.client_personal_data[key] = value
        })
        state.client.client_personal_data['birthday'] = (payload['birthday']) ? helpers.formatDate(payload['birthday']) : null
    },
    SET_CLIENT_DATA_FILTER(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.client.client_filter_data[key] = value
        })
        if (state.client.client_filter_data.rent_place == 1) {
            state.client.client_filter_data.selected_place = 'Lloguer'
        }
        if (state.client.client_filter_data.property_place == 1) {
            state.client.client_filter_data.selected_place = 'Propietat'
        }
        //state.client.client_filter_data.selected_place = state.client.client_filter_data.rent_place == 1 ? 'Lloguer' : 'Propietat'
    },
    SET_CLIENT_EPIGRAPHS(state, payload) {
        console.log(payload)
        Object.entries(payload).forEach(([key, value]) => {

            let epigr = []
            state.client.client_epigraphs[key] = value
            Object.entries(state.client.client_epigraphs[key].epigraphs).forEach(([key, value]) => {
                epigr.push(value.id)
            })

            state.client.client_epigraphs[key].epigraphs = epigr
            state.client.client_epigraphs[key].date_leave_activity = state.client.client_epigraphs[key].date_leave_activity ? helpers.formatDate(state.client.client_epigraphs[key].date_leave_activity) : null
        })
    },
    SET_CLIENT_PARTNERS(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.client.client_partners[key] = value
        })
    },
    SET_CLIENT_TAXES(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.client.client_epigraphs[key] = value.id
        })
    },
    SET_CLIENT_DOCUMENTS(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.client.client_documents[key] = value
            state.client.client_documents[key].date_update = state.client.client_documents[key].date_update ? helpers.formatDate(state.client.client_documents[key].date_update) : null
        })
    },
    SET_CLIENT_MODELS(state, payload) {

        const grouped = payload.reduce((carry, item) => {
            const flag = item.model.flag
            item.date_updated =  (item.date_updated) ? helpers.formatDate(item.date_updated) : null

            if (!carry[flag]) {
                carry[flag] = []
            }
            carry[flag].push(item)
            return carry
        }, {})

        if (grouped.hasOwnProperty('mod_036')) {
            state.client.models_records_036 = grouped.mod_036
        }
        if (grouped.hasOwnProperty('mod_037')) {
            state.client.models_records_037 = grouped.mod_037
        }
        if (grouped.hasOwnProperty('mod_210')) {
            state.client.models_records_210 = grouped.mod_210
        }
        if (grouped.hasOwnProperty('mod_600')) {
            state.client.models_records_600 = grouped.mod_600
        }
        if (grouped.hasOwnProperty('mod_650')) {
            state.client.models_records_650 = grouped.mod_650
        }
        if (grouped.hasOwnProperty('mod_652')) {
            state.client.models_records_652 = grouped.mod_652
        }
        if (grouped.hasOwnProperty('mod_653')) {
            state.client.models_records_653 = grouped.mod_653
        }
        if (grouped.hasOwnProperty('mod_660')) {
            state.client.models_records_660 = grouped.mod_660
        }
        if (grouped.hasOwnProperty('mod_902')) {
            state.client.models_records_902 = grouped.mod_902
        }
        if (grouped.hasOwnProperty('capital_gain')) {
            state.client.capital_gains = grouped.capital_gain
        }
        if (grouped.hasOwnProperty('open_model')) {
            state.client.models_records_open_models = grouped.open_model
        }
    },
    SET_VALIDATION_ERRORS(state, payload) {
        for (const key in payload) {
            state.validation_client[key] = '* ' + payload[key][0]
        }
    },
    CLEAR_VALIDATION(state) {
        for (const key in state.validation_client) {
            state.validation_client[key] = null
        }
    },
}

