import { POLICIES_LIST, POLICIES_VIEW, POLICIES_CREATE, POLICIES_EDIT } from '../../constants'

export default [
	{
		path: POLICIES_LIST,
		name: 'policies_list',
		component: () => import('@/views/insurance/policies/list/listView.vue'),
		meta: {
			pageTitle: 'Llistat pòlisses',
			permission: 'list_policies',
			breadcrumb: [
				{
					text: 'Llistat pòlisses',
					active: true,
				},
			],
		},
	},
	{
		path: POLICIES_CREATE,
		name: 'policies_create',
		component: () => import('@/views/insurance/policies/form/formView.vue'),
		meta: {
			pageTitle: 'Crear pòlissa',
			permission: 'create_policies',
			breadcrumb: [
				{
					text: 'Pòlisses',
                    to: POLICIES_LIST,
                },
				{
					text: 'Crear pòlissa',
					active: true,
				},
			],
		},
	},
	{
		path: `${POLICIES_VIEW}/:id`,
		name: 'policies_view',
		component: () => import('@/views/insurance/policies/view/sheetView.vue'),
		meta: {
			pageTitle: 'Ficha pòlissa',
			permission: 'view_policies',
			breadcrumb: [
				{
					text: 'Pòlisses',
					to: POLICIES_LIST,
				},
				{
					text: 'Ficha pòlissa',
					active: true,
				},
			],
		},
	},
	{
		path: `${POLICIES_EDIT}/:id`,
		name: 'policies_edit',
		component: () => import('@/views/insurance/policies/form/formView.vue'),
		meta: {
			pageTitle: 'Editar pòlissa',
			permission: 'edit_policies',
			breadcrumb: [
				{
					text: 'Pòlisses',
                    to: POLICIES_LIST,
                },
			],
		},
	},
]
