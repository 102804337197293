export const HOME = '/'
export const DASHBOARD = '/dashboard'

export const LOGIN = '/login'
export const REGISTER = '/register'
export const FORGOT_PASSWORD = '/forgot-password'
export const PAGE_404 = '/error-404'
export const NOT_AUTHORIZED = '/pages/miscellaneous/not-authorized'
export const UNDER_MAINTENANCE = '/pages/miscellaneous/under-maintenance'
export const ERROR = '/pages/miscellaneous/error'

export const RESPONSIBLE_LIST = '/responsables'

export const USER_LIST = '/usuarios/listado'
export const USER_CREATE = '/usuarios/crear'
export const USER_EDIT = '/usuarios/editar'

export const ROLES_LIST = '/roles/listado'
export const ROLES_CREATE = '/roles/crear'
export const ROLES_EDIT = '/roles/editar'

export const CLIENTS_LIST = '/clientes/listado'
export const CLIENTS_CREATE = '/clientes/crear'
export const CLIENTS_EDIT = '/clientes/editar'

export const POLICIES_LIST = '/seguros/polizas/listado'
export const POLICIES_VIEW = '/seguros/polizas/ver'
export const POLICIES_CREATE = '/seguros/polizas/crear'
export const POLICIES_EDIT = '/seguros/polizas/editar'

export const INVESTMENTS_LIST = '/financiero/inversiones/listado'
export const INVESTMENT_CREATE = '/financiero/inversiones/crear'
export const INVESTMENT_EDIT = '/financiero/inversiones/editar'

export const FINANCING_LIST = '/financiero/financiacion/listado'
export const FINANCING_CREATE = '/financiero/financiacion/crear'
export const FINANCING_EDIT = '/financiero/financiacion/editar'

export const REQUIREMENTS_LIST = '/contabilidad/requerimientos/listado'
export const REQUIREMENTS_CREATE = '/contabilidad/requerimientos/crear'
export const REQUIREMENTS_EDIT = '/contabilidad/requerimientos/editar'

export const SCRIPTURES_LIST = '/mercantil/escripturas/listado'
export const SCRIPTURES_CREATE = '/mercantil/escripturas/crear'
export const SCRIPTURES_EDIT = '/mercantil/escripturas/editar'

export const INHERITANCES_LIST = '/mercantil/herencias/listado'
export const INHERITANCES_CREATE = '/mercantil/herencias/crear'
export const INHERITANCES_EDIT = '/mercantil/herencias/editar'

export const TRADING_LIST = '/mercantil/mercantil/listado'
export const TRADING_CREATE = '/mercantil/mercantil/crear'
export const TRADING_EDIT = '/mercantil/mercantil/editar'

export const FORMALITIES_LIST = '/laboral/tramites/listado'
export const FORMALITIES_CREATE = '/laboral/tramites/crear'
export const FORMALITIES_EDIT = '/laboral/tramites/editar'