import state from './statisticsState'
import actions from './statisticsActions'
import mutations from './statisticsMutations'
import getters from './statisticsGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
