import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app/app'
import role from './role/role'
import user from './user/user'
import client from './client/client'
import notification from './notification/notification'
import insurancePolicies from './insurance/policy/policy'
import investments from "./finance/investment/investment"
import financing from "./finance/financing/financing"
import responsible from "./responsible/responsible"
import requirements from "./accounting/requirement/requirement"
import scriptures from "./trading/scripture/scripture"
import inheritances from "./trading/inheritance/inheritance"
import trading from "./trading/trading/trading"
import formalities from "./labor/formality/formality"
import auth from './auth/auth'
import appConfig from './app-config/appConfig'
import verticalMenu from './vertical-menu/verticalMenu'
import statistics from "./statistics/statistics"

import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import state from './state'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        auth,
        role,
        user,
        client,
        responsible,
        notification,
        insurancePolicies,
        investments,
        financing,
        requirements,
        scriptures,
        inheritances,
        trading,
        formalities,
        appConfig,
        verticalMenu,
        statistics
    },
    state,
    actions,
    getters,
    mutations,
    strict: process.env.DEV,
})