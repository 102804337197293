import axios from '@/resources/libs/axios'

export default {
	find(id, data) {
		return axios.get(`/trading/scripture/find/${id}`, {params: data})
	},
	search(data) {
		return axios.get(`/trading/scripture/list`, {params: data})
	},
	create(data) {
		return axios.post(`/trading/scripture/create`,  data)
	},
	update(data) {
		return axios.post(`/trading/scripture/update`, data)
	},
	delete(id) {
		return axios.delete(`/trading/scripture/delete/${id}`)
	},
	getAllNotaries() {
		return axios.get(`/trading/scripture/notaries/list/all`)
	},
	deleteDocument(id) { //ate agora nao usa
		return axios.delete(`/trading/scripture/documents/delete/${id}`)
	},
	deleteScriptureModelRegister(id) {
		return axios.delete(`/trading/scripture/model/delete/${id}`)
	},
	deleteScriptureProperty(id) {
		return axios.delete(`/trading/scripture/property/delete/${id}`)
	},
	deleteScriptureInvoice(id) {
		return axios.delete(`/trading/scripture/invoice/delete/${id}`)
	},

}