import {updateField} from "vuex-map-fields"
import {getDefaultState} from "./investmentState"
import {investmentsFinancialEntities, investmentStatus} from "@/constants/finance"
import helpers from "@/resources/helpers/helpers"

export default {
    updateField,
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
    SET_INVESTMENTS(state, payload) {
        state.investments = payload
    },
    SET_INVESTMENT(state, payload) {

        let dates = ['entry_date', 'date_expiration']

        dates.forEach(date => {
            state.investment[date] = (payload[date]) ? helpers.formatDate(payload[date]) : null
        })

        Object.entries(payload).forEach(([key, value]) => {
            state.investment[key] = value
        })

    },
    SET_INVESTMENT_DOCUMENTS(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.investment.documents[key] = value
            state.investment.documents[key].date_update = state.investment.documents[key].date_update ? helpers.formatDate(state.investment.documents[key].date_update) : null
        })
    },
    SET_VALIDATION_ERRORS(state, payload) {
        for (const key in payload) {
            state.validation_investment[key] = '* ' + payload[key][0]
        }
    },
    CLEAR_VALIDATION(state) {
        for (const key in state.validation_investment) {
            state.validation_investment[key] = null
        }
    },
}

