import { updateField } from "vuex-map-fields"
import { getDefaultState } from "./financingState"
import {investmentsFinancialEntities, investmentStatus} from "@/constants/finance"
import helpers from "@/resources/helpers/helpers"

export default {
    updateField,
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
    SET_FINANCINGS(state, payload) {
        let records = []
        for (let i = 0; i < payload.length; i++) {

            records.push({
                financing_entity: payload[i].financing_entity !== '' ? investmentsFinancialEntities.find(element => element.value === payload[i].financing_entity).name : '',
                client: payload[i].client.name + ' ' +payload[i].client.first_surname + ' ' +payload[i].client.second_surname,
                second_holder_name: payload[i].second_holder_name,
                client_id: payload[i].client.id,
                amount: payload[i].amount,
                status:  payload[i].status !== null ? investmentStatus.find(element => element.value === payload[i].status).name : '',
                id: payload[i].id
            })

        }
        state.financings = records
    },
    SET_FINANCING(state, payload) {

        Object.entries(payload).forEach(([key, value]) => {
            state.financing[key] = value
        })

        let dates = ['renewal_date', 'expiration_date', 'entry_date', 'signature_date']

        dates.forEach(date => {
            state.financing[date] = (payload[date]) ? helpers.formatDate(payload[date]) : null
        })

    },
    SET_FINANCING_DOCUMENTS(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.financing.documents[key] = value
            state.financing.documents[key].date_update = state.financing.documents[key].date_update ? helpers.formatDate(state.financing.documents[key].date_update) : null
        })
    },
    SET_FINANCING_HOLDERS(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.financing.financing_holders[key] = value
        })
    },
    SET_VALIDATION_ERRORS(state, payload) {
        for (const key in payload) {
            state.validation_financing[key] = '* '+payload[key][0]
        }
    },
    CLEAR_VALIDATION(state) {
        for (const key in state.validation_financing) {
            state.validation_financing[key] = null
        }
    },
}