import { FORMALITIES_LIST, FORMALITIES_CREATE, FORMALITIES_EDIT } from '../../constants'

export default [
	{
		path: FORMALITIES_LIST,
		name: 'formalities_list',
		component: () => import('@/views/labor/formalities/list/listView.vue'),
		meta: {
			pageTitle: 'Llistat tràmits',
			permission: 'list_formalities',
			breadcrumb: [
				{
					text: 'Llistat tràmits',
					active: true,
				},
			],
		},
	},
	{
		path: FORMALITIES_CREATE,
		name: 'formalities_create',
		component: () => import('@/views/labor/formalities/form/formView.vue'),
		meta: {
			pageTitle: 'Crear tràmit',
			permission: 'create_formalities',
			breadcrumb: [
				{
					text: 'Tràmits',
                    to: FORMALITIES_LIST,
                },
				{
					text: 'Crear tràmit',
					active: true,
				},
			],
		},
	},
	{
		path: `${FORMALITIES_EDIT}/:id`,
		name: 'formalities_edit',
		component: () => import('@/views/labor/formalities/form/formView.vue'),
		meta: {
			pageTitle: 'Editar tràmit',
			permission: 'edit_formalities',
			breadcrumb: [
				{
					text: 'Tràmits',
                    to: FORMALITIES_LIST,
                },
			],
		},
	},
]
