export const investmentsFinancialEntities = [
	{value: 'bbva' , name: 'BBVA'},
	{value: 'colaboration_sabadell', name: 'Collaboració Sabadell'},
	{value: 'colaboration_santander', name: 'Collaboració Santander'},
	{value: 'allianz', name: 'Allianz'},
]
export const investmentStatus = [
	{value: 'in_progress' , name: 'En Curs'},
	{value: 'accepted', name: 'Acceptat'},
	{value: 'cancelled', name: 'Cancel·lat'},
	{value: 'denied', name: 'Denegada'},
]

export const investmentTypes = [
	{value: 'new' , name: 'Nova'},
	{value: 'renovation', name: 'Renovació'},
]

export const financingTypeClient = [
	{value: 'company' , name: 'Empresa'},
	{value: 'particular', name: 'Particular'},
]

