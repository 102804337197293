import state from './scriptureState'
import actions from './scriptureActions'
import mutations from './scriptureMutations'
import getters from './scriptureGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
