import axios from '@/resources/libs/axios'

export default {
    create(data) {
        return axios.post(`/finance/financing/create`,  data)
    },
    update(data) {
        return axios.post(`/finance/financing/update`, data)
    },
    search(data) {
        return axios.get(`/finance/financing/list`, {params: data})
    },
    listAll(data) {
        return axios.get(`/finance/financing/list/all`, {params: data})
    },
    find(id, data) {
        return axios.get(`/finance/financing/find/${id}`, {params: data})
    },
    delete(id) {
        return axios.delete(`/finance/financing/delete/${id}`)
    },
    updateDocuments(data)
    {
        return axios.post(`/finance/financing/documents`, data)
    },
    deleteDocument(id) {
        return axios.post(`/finance/financing/documents/delete/${id}`)
    },
    getDocumentsByCategory(id) {
        return axios.get(`/finance/financing/documents/types/${id}`)
    },
    deleteFinancingHolder(id) {
        return axios.delete(`/finance/financing/holder/delete/${id}`)
    },
}