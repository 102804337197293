import state from './policyState'
import actions from './policyActions'
import mutations from './policyMutations'
import getters from './policyGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
