export default {
	roles: [],
	permissions: [],
	validation: {
		name: null,
		type: null,
		department_id: null,
		permissions: null,
	},
}
