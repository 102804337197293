import state from './formalityState'
import actions from './formalityActions'
import mutations from './formalityMutations'
import getters from './formalityGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
