export const getDefaultState = () => {
    return {
        formalities: [],
        formality: {
            id: null,
            client_id: null,
            type: null,
            formality_documents: []
        },

        validation_formality: {
            client_id: null,
            type: null,
        },


    }
}

export default getDefaultState()
