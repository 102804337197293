import api from "@/router/api"
import store from "@/store"

export default {
	searchInheritances({commit}, payload){
		return new Promise((resolve, reject) => {
			api.inheritances.search(payload)
				.then(response => {
					const {data} = response.data

					commit('SET_INHERITANCES', data.data)

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener las heranzas'
					})
				})
		})
	},
	deleteInheritance({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.inheritances.delete(payload.id)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar la escritura.',
				})
			})
		})
	},
	getInheritance({commit}, payload){
		return new Promise((resolve, reject) => {
			api.inheritances.find(payload.id, payload)
				.then(response => {
					commit('SET_INHERITANCE', response.data.data)
					resolve(response.data.data)

					if(response.data.data.documents){
						commit('SET_INHERITANCES_DOCUMENTS', response.data.data.documents)
					}

					if(response.data.data.properties){
						commit('SET_INHERITANCES_PROPERTIES', response.data.data.properties)
					}

					if(response.data.data.invoices){
						commit('SET_INHERITANCES_INVOICES', response.data.data.invoices)
					}

					if (response.data.data.client){
						store.commit('client/SET_CLIENT', response.data.data.client)
					}

					if (response.data.data.client.client_personal_data){
						store.commit('client/SET_CLIENT_PERSONAL_DATA', response.data.data.client.client_personal_data)
					}

					if (response.data.data.client.client_filter_data){
						store.commit('client/SET_CLIENT_DATA_FILTER', response.data.data.client.client_filter_data)
					}

					if (response.data.data.client.client_models){
						store.commit('client/SET_CLIENT_MODELS', response.data.data.client.client_models)
					}

				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al obtener el cliente',
					})
				})
		})
	},
	createInheritance({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.inheritances.create(payload)
				.then(response => {
					const {data} = response.data

					commit('SET_INHERITANCE', response.data.data)

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	updateInheritance({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.inheritances.update(payload)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	deleteInheritanceModelRegister({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.inheritances.deleteInheritanceModelRegister(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar el modelo.',
				})
			})
		})
	},
	deleteInheritanceInvoice({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.inheritances.deleteInheritanceInvoice(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar el documento.',
				})
			})
		})
	},
	deleteInheritanceProperty({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.inheritances.deleteInheritanceProperty(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar la propriedad.',
				})
			})
		})
	},
	getDocumentsByCategory({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.inheritances.getDocumentsByCategory(payload.category)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al obtener los tipos de documentos.',
				})
			})
		})

	},
	deleteInheritanceDocumentCategory({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.inheritances.deleteInheritanceDocumentCategory(payload)
			.then(response => {
				const {data} = response.data

				resolve(data)
			})
			.catch(() => {
				reject({
					message: 'Error al eliminar el documento.',
				})
			})
		})
	},

}
